<template>
  <div>
    <a-modal
      :visible="showDetail"
      :title="'Detil Kehadiran ' + detilLog.name"
      @cancel="clickShowDetail"
      centered
      closable
    >
      <DetilLogKehadiran :data="detilLog" :logDate="selectDate" :isDetailRekapKehadiran="isDetailRekapKehadiran" />
      <template slot="footer">
        <a-button class="d-none" key="back"></a-button>
        <a-button class="d-none" key="submit"></a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items-center">
      <!-- <div class="mr-3">
        <a-radio-group @change="handleViewLog" v-model="viewLog" button-style="solid" size="large">
          <a-radio-button value="period">
            Periode
          </a-radio-button>
          <a-radio-button value="month">
            Bulan
          </a-radio-button>
          <a-radio-button value="date">
            Tanggal
          </a-radio-button>
        </a-radio-group>
      </div> -->
      <div class="mr-3">
        <!-- <a-select size="large" v-if="viewLog !== 'date'" class="w-100" v-model="selectMonth">
          <a-select-option v-for="(item) in months" :key="item.id" :value="item.id">
            {{item.bulan}}
          </a-select-option>
        </a-select> -->
        <a-date-picker size="large" v-model="selectDate" @change="onSelectChange" />
      </div>
      <!-- <div class="mr-3">
        <a-select v-if="viewLog !== 'date'" size="large" class="w-100" v-model="selectYear">
          <a-select-option v-for="(item, index) in years" :key="index" :value="item">
            {{item}}
          </a-select-option>
        </a-select>
      </div> -->
      <div class="mr-3">
        <a-input size="large" @change="e => fetchDataTable({ search: e.target.value })" placeholder="Cari pegawai..." />
      </div>
      <div class="mr-3">
        <a-select
          size="large"
          v-model="selectUnit"
          @change="onSelectChange"
        >
          <a-select-option key="all">Semua Unit</a-select-option>
          <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{unit.nama}}</a-select-option>
        </a-select>
      </div>
      <div class="ml-auto">
          <a-modal
          title="Cetak Data Kehadiran"
          :visible="visibleModalExport"
          @cancel="handleVisibleModalExport"
        >
          <a-form>
            <a-form-item label="Employee Type">
              <a-select
                size="large"
                default-value="All"
              >
                <a-select-option key="All">All Employee</a-select-option>
                <a-select-option key="TK">KB-TK</a-select-option>
                <a-select-option key="SD">SD</a-select-option>
                <a-select-option key="SMP">SMP</a-select-option>
                <a-select-option key="Employee">Employee</a-select-option>
              </a-select>
            </a-form-item>
          <a-form-item label="Period Data">
            <a-select
              size="large"
              v-model="periodData"
              @change="periodOnChange"
            >
              <a-select-option key="This Month">This Month</a-select-option>
              <a-select-option key="Last Month">Last Month</a-select-option>
              <a-select-option key="3Month">Last 3 Month</a-select-option>
              <a-select-option key="Period">By Period</a-select-option>
              <a-select-option key="Date">By Date</a-select-option>
            </a-select>
          </a-form-item>
          <div v-if="periodData === 'Period'" class="row">
            <div class="col-lg-6">
              <a-form-item label="Month">
                <a-select
                  size="large"
                  default-value="January"
                >
                  <a-select-option key="January">January</a-select-option>
                  <a-select-option key="February">February</a-select-option>
                  <a-select-option key="March">March</a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item label="Year">
                <a-select
                  size="large"
                  default-value="2021"
                >
                  <a-select-option key="2019">2019</a-select-option>
                  <a-select-option key="2020">2020</a-select-option>
                  <a-select-option key="2021">2021</a-select-option>
                </a-select>
              </a-form-item>
              </div>
            </div>
            <div v-if="periodData === 'Date'" class="row">
              <div class="col-lg-6">
                <a-form-item label="Start at">
                    <a-date-picker
                      v-model="startAt"
                      :disabled-date="disabledStartDate"
                      show-time
                      format="YYYY-MM-DD"
                      @openChange="handleStartOpenChange"
                      style="width: 100%"
                      size="large"
                    />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item label="End at">
                  <a-date-picker
                    v-model="endAt"
                    :disabled-date="disabledEndDate"
                    show-time
                    format="YYYY-MM-DD"
                    :open="endOpen"
                    @openChange="handleEndOpenChange"
                    style="width: 100%"
                    size="large"
                  />
                </a-form-item>
              </div>
            </div>
            <a-alert message="21-03-2021 to 20-04-2021" type="info" show-icon />
          </a-form>
          <template slot="footer">
              <a-button key="back" @click="handleVisibleModalExport">
              Cancel
              </a-button>
              <a-button key="submit" type="primary" :loading="confirmLoadingModalAttendance" @click="handleOkModalExport">
              Cetak Sekarang
              </a-button>
          </template>
        </a-modal>
        <a-button @click.prevent="handleVisibleModalExport" type="danger" size="large">
          <a-icon type="export" /> Cetak
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <!-- <a-table v-if="viewLog !== 'date'" :columns="columns2" :data-source="dataTable" bordered :scroll="{ x: 1500 }">
        <span slot="status" slot-scope="value">
          <a-tag
            :color="value.status === 'Hadir' ? 'green' : value.status === 'Absen' ? 'volcano' : value.status === 'Cuti' ? 'orange' : ''"
          >
            {{ value.status.toUpperCase() }}
          </a-tag><br>
          <p class="mt-2">{{value.inTime}} - {{value.outTime}}</p>
        </span>
        <div slot="nullChecker" slot-scope="value">
          {{value || '-'}}
        </div>
      </a-table> -->
      <a-table :columns="columns"
        :data-source="dataTable"
        :loading="loadingTable" bordered
        @change="handleTableChange"
        :pagination="pagination">
        <div slot="name" slot-scope="value" class="d-flex align-items-center">
          <!-- <a-avatar class="bg-primary mr-2">
            J
          </a-avatar> -->
          {{value}}
        </div>
        <div slot="nullChecker" slot-scope="value">
          {{value || '-'}}
        </div>
        <span slot="status" slot-scope="value, record">
          <a-tag
            v-for="status in record.status"
            :key="status"
            :color="status === 'hadir' ? 'green' : status === 'alfa' ? 'volcano' : value === 'cuti' ? 'orange' : ''"
          >
            {{ status.toUpperCase() }}
          </a-tag>
        </span>
        <div slot="action" slot-scope="value, record">
          <a-button @click.prevent="clickShowDetail(record)" size="large"><a-icon type="container" />Detil</a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
const DetilLogKehadiran = () => import('@/components/app/DetilLogKehadiran')
const columns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Kehadiran',
    dataIndex: 'attendance',
    key: 'attendance',
    children: [
      {
        title: 'Masuk',
        dataIndex: 'jam_masuk',
        key: 'jam_masuk',
        align: 'center',
        scopedSlots: { customRender: 'nullChecker' },
      },
      {
        title: 'Keluar',
        dataIndex: 'jam_keluar',
        key: 'jam_keluar',
        align: 'center',
        scopedSlots: { customRender: 'nullChecker' },
      },
    ],
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

const columns2 = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    fixed: 'left',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Kehadiran Januari',
    dataIndex: 'attendance',
    key: 'attendance',
    children: [
      {
        title: '4 January',
        dataIndex: '4',
        key: 4,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '5 January',
        dataIndex: '5',
        key: 5,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '6 January',
        dataIndex: '6',
        key: 6,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '7 January',
        dataIndex: '7',
        key: 7,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '8 January',
        dataIndex: '8',
        key: 8,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '11 January',
        dataIndex: '11',
        key: 11,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '12 January',
        dataIndex: '12',
        key: 12,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '13 January',
        dataIndex: '13',
        key: 13,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '14 January',
        dataIndex: '14',
        key: 14,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
      {
        title: '15 January',
        dataIndex: '15',
        key: 15,
        align: 'center',
        scopedSlots: { customRender: 'status' },
        width: 130,
      },
    ],
  },
]

export default {
  data() {
    return {
      showDetail: false,
      dataTable: [],
      columns,
      columns2,
      viewLog: 'period',
      periodData: 'This Month',
      confirmLoadingModalAttendance: false,
      visibleModalExport: false,
      selectDate: moment(),
      months: [],
      units: [],
      years: [],
      selectMonth: null,
      selectUnit: 'all',
      selectYear: null,
      loadingTable: false,
      pagination: {},
      detilLog: {},
      isDetailRekapKehadiran: false,
    }
  },
  components: {
    DetilLogKehadiran,
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
    clickShowDetail(record) {
      console.log('record :>> ', record)
      this.showDetail = !this.showDetail
      if (this.showDetail) {
        this.detilLog = {
          ...record,
        }
      } else {
        setTimeout(() => {
          this.detilLog = {}
        }, 300)
      }
    },
    handleViewLog(e) {
      this.viewLog = e.target.value
      // console.log(this.viewLog)
    },
    disabledStartDate(startValue) {
      const endValue = this.endAt
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startAt
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleVisibleModalExport() {
      this.visibleModalExport = !this.visibleModalExport
    },
    handleOkModalExport() {
      console.log('Exported')
    },
    onSelectChange() {
      this.fetchDataTable()
    },
    periodOnChange(value) {
      this.periodData = value
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('adminHR/FETCH_ALL_ABSENT_LOG', {
        page: params.page,
        order: params.order,
        search: params.search,
        sortBy: params.sortBy,
        unit: this.selectUnit,
        dateLog: moment(this.selectDate).format('YYYY-MM-DD'),
      })
        .then(({ data, total }) => {
          // console.log(res)
          // change col attendance
          // this.columns2 = this.columns2.map(col => {
          //   if (col.dataIndex === 'attendance') {
          //     const { bulan } = this.months.find(month => month.id === this.selectMonth)
          //     // col.children = this.
          //     return { ...col, title: 'Kehadiran ' + bulan }
          //   } else return col
          // })

          this.dataTable = data.map(teacher => {
            let status = []
            let others = {}
            if (teacher.absensis.length) {
              const dataAbsen = teacher.absensis[0]
              status.push('hadir')
              others = {
                ...dataAbsen,
                id_absen: dataAbsen ? dataAbsen.id : null,
                jam_masuk: dataAbsen.jam_masuk ? moment(dataAbsen.jam_masuk, 'HH:mm:ss').format('HH:mm') : null,
                jam_keluar: dataAbsen.jam_keluar ? moment(dataAbsen.jam_keluar, 'HH:mm:ss').format('HH:mm') : null,
              }
            }
            if (teacher.pengajuan_cutis.length) {
              others.id_cuti = teacher.pengajuan_cutis[0].id
              status.push('cuti')
            }
            if (teacher.pengajuan_izins.length) {
              const statusIzin = teacher.pengajuan_izins.map(izin => { return izin.tipe })
              status = status.concat(statusIzin)
            }
            if (!teacher.absensis.length && !teacher.pengajuan_cutis.length && !teacher.pengajuan_izins.length) status.push('alfa')
            return {
              key: teacher.id,
              name: teacher.nama,
              unit: teacher.unit ? teacher.unit.nama : null,
              status,
              ...others,
            }
          })
          this.loadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = total
          this.pagination = pagination
          // console.log(res)
          // this.dataTable = data.map(el => {
          //   return {
          //     key: el.id,
          //     id: el.id,
          //     name: el.nama,
          //     unit: el.unit,
          //   }
          // })
        })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
      })
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
  created() {
    this.loadingTable = true
    this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: 'all' })
      .then(datas => {
        // console.log(datas)
        if (datas.length) {
          this.selectMonth = datas[0].id
          this.selectYear = datas[0].tahun
        }
        datas.forEach(periode => {
          if (!this.years.includes(periode.tahun)) this.years.push(periode.tahun)
        })
        // this.years = dataTahun.map(data => { return data.tahun })
        this.months = datas.map(data => { return { id: data.id, bulan: data.bulan, tahun: data.tahun } })
        return this.$store.dispatch('absensiKaryawan/FETCH_ALL_UNIT')
      })
      .then(datas => {
        console.log(datas)
        this.units = datas.map(data => { return { id: data.id, nama: data.nama } })
        this.fetchDataTable()
      })
  },
}
</script>
